import VueRouter from 'vue-router'
import AboutUs from "../pages/index/aboutUs";
import MediationRules from "../pages/index/mediationRules";
import PhotoWall from "../pages/index/photoWall";
import ContactUs from "../pages/index/contactUs";
import MediationRates from "../pages/index/mediationRates";
import OnlineApply from "../pages/index/apply";
import FeedBack from "../pages/index/feedback";
import MediatorList from "../pages/index/mediatorList";
import GoldMediator from "../pages/index/goldMediator";
import CourtMediator from "../pages/index/courtMediator";
import DutyMediator from "../pages/index/dutyMediator";
import NotFound from "../pages/index/notFound";
import CaseQuery from "../pages/case/query";
import CaseList from "../pages/case/list";
import CaseDetail from "../pages/case/caseDetail";
import NewsList from "../pages/news/list";
import NewsDetail from "../pages/news/detail";

export default new VueRouter({
	routes: [
		{
			path: '/',
			component: AboutUs,
			meta: {
				title: '汕尾市和融调解中心',
				content:{
					keywords:'汕尾市和融调解中心',
					description:'汕尾市和融调解中心依法依规开展商事类、金融类纠纷调解活动；接受相关部门、企业、个人委托的商事类、金融类纠纷调解工作；提供商事类、金融类纠纷法律咨询服务，开展法律知识宣传推广活动，调解员培训、诉讼辅导。',
				}
			}
		},
		{
			path: '/aboutUs',
			component: AboutUs,
			meta: {
				title: '汕尾市和融调解中心 - 调解中心简介',
			}
		},
		{
			path: '/mediationRules',
			component: MediationRules,
			meta: {
				title: '汕尾市和融调解中心 - 调解规则',
			}
		},
		{
			path: '/photoWall',
			component: PhotoWall,
			meta: {
				title: '汕尾市和融调解中心 - 照片墙',
			}
		},
		{
			path: '/contactUs',
			component: ContactUs,
			meta: {
				title: '汕尾市和融调解中心 - 联系我们',
			}
		},
		{
			path: '/mediationRates',
			component: MediationRates,
			meta: {
				title: '汕尾市和融调解中心 - 收费标准',
			}
		},
		{
			path: '/apply',
			component: OnlineApply,
			meta: {
				title: '汕尾市和融调解中心 - 申请登记',
			}
		},
		{
			path: '/feedback',
			component: FeedBack,
			meta: {
				title: '汕尾市和融调解中心 - 意见反馈',
			}
		},
		{
			path: '/mediatorList',
			component: MediatorList,
			meta: {
				title: '汕尾市和融调解中心 - 调解员名录',
			}
		},
		{
			path: '/goldMediator',
			component: GoldMediator,
			meta: {
				title: '汕尾市和融调解中心 - 金牌调解员',
			}
		},
		{
			path: '/courtMediator',
			component: CourtMediator,
			meta: {
				title: '汕尾市和融调解中心 - 驻法院调解员',
			}
		},
		{
			path: '/dutyMediator',
			component: DutyMediator,
			meta: {
				title: '汕尾市和融调解中心 - 值班调解员',
			}
		},
		{
			path: '/case/query',
			component: CaseQuery,
			meta: {
				title: '汕尾市和融调解中心 - 案件查询',
			}
		},
		{
			path: '/case/list',
			component: CaseList,
			meta: {
				title: '汕尾市和融调解中心 - 案件列表',
			}
		},
		{
			path: '/case/detail',
			component: CaseDetail,
			meta: {
				title: '汕尾市和融调解中心 - 案件详情',
			}
		},
		{
			path: '/news/list',
			component: NewsList,
			meta: {
				title: '汕尾市和融调解中心 - 新闻列表',
			}
		},
		{
			path: '/news/detail',
			component: NewsDetail,
			meta: {
				title: '汕尾市和融调解中心 - 新闻详情',
			}
		},
		{
			path: '*',
			component: NotFound,

		},
	]
})