<template>
    <div class="container-fluid">
        <h5>调 解 案 件<br/>MEDIATION CASE</h5>
        <div>
            <b-list-group>
                <b-list-group-item active>调解信息</b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">调解案号：</b-col>
                        <b-col>{{ caseDetail.caseCode }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">被申请人：</b-col>
                        <b-col>{{ caseDetail.borrowerName }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">诉讼标的：</b-col>
                        <b-col>{{ caseDetail.mediatePrincipal }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">受理阶段：</b-col>
                        <b-col>诉前调解</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">调解员：</b-col>
                        <b-col>{{ caseDetail.mediateUserName }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">联系电话：</b-col>
                        <b-col>0660-8348668/8348660</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">投诉电话：</b-col>
                        <b-col>刘秘书18675811825</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-button variant="link" style="color: red; font-weight: bolder;" @click="generateReport">★★★点击下载调解告知函★★★</b-button>
                </b-list-group-item>
            </b-list-group>

            <b-list-group>
                <b-list-group-item active></b-list-group-item>
                <b-list-group-item>
                    <img style="max-width: 100%;" src="@/assets/flow_chart.jpg"/>
                </b-list-group-item>
            </b-list-group>

            <b-list-group>
                <b-list-group-item active>关于调解中心</b-list-group-item>
                <b-list-group-item>
                    <p>汕尾市和融调解中心是在汕尾市民政局的批准下成立，其主要职责是调解平等主体的自然人、法人和其他组织之间发生的合同、金融、网络服务、电子商务、知识产权等领域民商事纠纷； 提供与调解相关的法律咨询服务； 开展与调解相关的研究、培训及宣传推广活动； 接受政府机关、司法机关、金融机构、企事业单位和其他社会组织的委托或者邀请，调解相关财产权益纠纷。</p>
                </b-list-group-item>
            </b-list-group>
        </div>
        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                :filename="fileName"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="800px"
                :html-to-pdf-options="htmlToPdfOptions"

                ref="html2Pdf"
        >
            <section slot="pdf-content">
                <!-- PDF Content Here -->
                <div class="pdf-doc">
                    <div class="pdf-doc-title">
                        <h1 >汕尾市和融调解中心</h1>
                        <p>{{ caseDetail.caseCode }}</p>
                        <hr>
                    </div>
                    <div class="pdf-doc-content">
                        <p>被申请人：{{ caseDetail.borrowerName }}</p>
                        <p>身份证号：{{ caseDetail.borrowerIdcardNo }}</p>
                        <p>&nbsp;</p>

                        <p class="case-content">
                            关于{{ caseDetail.loanServicePlatform }}与{{ caseDetail.borrowerName }}经济合同纠纷一案，我中心已正式受理，根据《中华人民共和国人民调解法》、《最高人民法院关于人民法院进一步深化多元化纠纷解决机制改革的意见》等规定，依法通知当事人进行诉前调解。调解中心电话:{{ caseDetail.mediateUserPhone }}
                        </p>

                        <p>&nbsp;</p>
                        <div class="case-company">
                            <p>汕尾市和融调解中心</p>
                            <p>{{ caseDetail.createTime }}</p>
                            <img src="@/assets/company-seal.png"/>
                        </div>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
	import axios from "axios";
	import VueHtml2pdf from 'vue-html2pdf'

	export default {
		name: "CaseDetail",
		data() {
			return {
				caseDetail: {
					"createTime": ""
                },
				fileName: "调解函",
				htmlToPdfOptions: {
					margin: 0,
					filename: "调解函001",
				}
			}
		},
		components: {
			VueHtml2pdf
		},
		filters: {
			dateFilters: function (value) {
				return value.substring(0,10)
            },
			statusFilters: function (value) {
				let val = ""

				switch (value) {
					case 0:
						val = "未调解"
						break
					case 1:
						val = "调解中"
						break
					case 2:
						val = "结案"
						break
					case 3:
						val = "冻结"
						break
				}
				return val
			},
		},
		created() {
			this.getCaseDetail()
		},
		methods: {
			getCaseDetail() {
				axios.get( process.env.VUE_APP_BASE_API + "/v1/caseInfo/detail"  + "?identityId=" + this.$route.query.identityId + "&caseId=" + this.$route.query.caseId).then(
					response => {
						this.caseDetail = response.data.data
                        this.htmlToPdfOptions.filename = response.data.data.caseCode
					},
					error => {
						console.log(error)
					}
				)
			},
			generateReport() {
				this.$refs.html2Pdf.generatePdf()
			},
		}
	}
</script>

<style scoped>
    >>>.list-group .active {
        background-color: #c5a660;
        border-color: #c5a660;
    }

    .list-group p {
        text-align: justify;
        text-indent: 2rem;
    }

    .row>.col-3:first-child {
        font-weight: bolder;
        text-align: right;
        padding-right: 0px;
        padding-left: 0px;
    }
    .padLR0 {
        padding-left:0;
        padding-right:0;
    }
    svg{
        margin-top: 0.5rem;
    }
    .line-table {
        border-right: 2px solid #0e93d7;
        border-bottom: 2px solid #0e93d7;
        width: 100%;
    }
    .line-table-2 {
        border-left: 2px solid #0e93d7;
        width: 100%;
        position: relative;
    }
    .line-table-2:before {
        content: "";
        position: absolute;
        border: 4px solid transparent;
        border-top-color: #0e93d7;
        left: -4px;
        bottom: -4px;
    }


    .pdf-doc {
        width: 800px;
        height: 1024px;
        margin: 0 auto;
    }
    .pdf-doc-title {
        text-align: center;
        color: #AD1728;
    }
    h1{
        margin-top: 80px;
        font-size: 40px;
        font-weight: bolder;
    }
    .pdf-doc-title p{
        font-size: 25px;
        color: black;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    hr {
        border: none;
        height: 2px;
        width: 90%;
        background-color: #AD1728;
    }
    .pdf-doc-content {
        margin:0 auto;
        width: 90%;
    }
    .pdf-doc-content h1 {
        text-align: center;
    }
    .pdf-doc-content p {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 3rem;
    }
    .case-content {
        text-indent: 2rem;
    }
    .case-company {
        position: relative;
        text-align: center;
        float: right;
        margin-right: 4rem;
    }
    .case-company img {
        width: 180px;
        height: 180px;
        position: absolute;
        top: -4rem;
        left: 4rem;
    }

</style>